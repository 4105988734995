import * as React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import SEO from '../components/seo';
import CookieBar from '../components/cookiebar';
import Footer from '../components/footer';
import SectionWhite from '../components/sectionwhite';
import SectionBG from '../components/sectionbg';
import HeroGirl from '../images/hero-daeyeon.png';
import ButtonPlainExternal from '../components/buttonplain-external';
import ButtonPlain from '../components/buttonplain';
import PreviewBackground from '../images/app-preview-background.jpg';

const HomePage = () => {
  return (
    <div>
      <SEO />
      <CookieBar />
      <SectionBG bgimage={PreviewBackground}>
        <div className="relative w-auto md:w-1/3 top-4 md:-top-24 lg:-top-36  md:float-right">
          <img className="m-auto max-h-96 md:max-h-tall" src={HeroGirl} />
        </div>
        <div className="md:w-1/2 text-center md:text-left md:float-left">
          <div>
            <a href="/">
              <StaticImage
                src="../images/littlelikeme-logo-white.png"
                alt="littlelike.me ™"
                height={100}
                placeholder="none"
              />
            </a>
          </div>

          <p className="leading-relaxed mb-4 mt-8">
            Want to know what's changed in the latest version of the game? Check
            out the Patch Notes.
          </p>
          <p className="leading-relaxed mb-4">
            Found a bug? Have a question or feedback for the developers? Use
            Feedback.
          </p>
          <ButtonPlainExternal
            buttonText="PATCH NOTES"
            buttonLink="/patchnotes"
          />
          <ButtonPlainExternal
            buttonText="FEEDBACK"
            buttonLink="/feedback"
          />
          <ButtonPlain
            buttonText="ABOUT"
            buttonLink="/"
          />
           <p className="leading-relaxed mb-4 mt-4">
            <a href="https://twitter.com/littlelikeDOTme">
              <StaticImage
                className="mr-4"
                placeholder="none"
                src="../images/twitter.png"
                width={60}
                alt="Twitter"
              />
            </a>
            <a href="https://instagram.com/littlelike.me">
              <StaticImage
                className="mr-4"
                placeholder="none"
                src="../images/instagram.png"
                width={60}
                alt="Instagram"
              />
            </a>
          </p>
        </div>
      </SectionBG>
      <Footer />
    </div>
  );
};

export default HomePage;
