import * as React from 'react';
import {Link} from 'gatsby';

const ButtonPlain = ({buttonText, buttonLink}) => {
  return (
    <Link to={buttonLink}>
      <button className="inline-block mx-1 my-1 px-8 py-3 rounded-md text-gray-900 bg-white border border-gray-400 hover:border-gray-600 uppercase text-xs font-bold tracking-wider">
        {buttonText}
      </button>
    </Link>
  );
};

export default ButtonPlain;
